.formContainer.formContainer {
  flex: 1;
  width: 80%;
  align-self: center;
  transition: opacity 1300ms ease-in;
}

.payButton.payButton {
  margin-top: 40px;
}

.login {
  margin-top: 20px;
}

.checkoutContainer.checkoutContainer {
  margin-top: 54px;
}

.loader.loader {
  justify-content: center;
  height: 100vh;
}
