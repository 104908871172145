.planOverviewContainer.planOverviewContainer {
  background: var(--VIOLET_LIGHT);
  border-radius: 24px;
  flex-direction: row;
  height: 207px;
  overflow: hidden;
  justify-content: space-between;
}
.planOverviewContainerWithPriceDuration.planOverviewContainerWithPriceDuration {
  height: 262px;
}

.planTitleWrapper.planTitleWrapper {
  justify-content: center;
  margin-left: 10%;
}
.coach {
  height: 268px;
  margin-right: 10%;
  margin-top: 30px;
}

.priceDurationWrapper.priceDurationWrapper {
  margin-top: 33px;
}

@media only screen and (max-width: 780px) {
  .coach {
    display: none;
  }
}
