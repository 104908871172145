.text {
  text-decoration-line: underline;
  font-size: 14px;
  line-height: 14px;
  color: var(--GREY_DARK);
  font-family: MEDIUM;
}

.text:hover {
  color: var(--DARK_BLUE);
}
