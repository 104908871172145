.container.container {
  margin-top: 36px;
}

.accountOptionsContainer.accountOptionsContainer {
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
}

.accountOption {
  text-decoration-line: underline;
  font-size: 14px;
  line-height: 14px;
  color: #999dac;
  font-family: MEDIUM;
}
