.promoCodeContainer {
  margin-bottom: 24px;
}

.couponContainer.couponContainer {
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
}

.amountOff {
  font-size: 18px;
  line-height: 18px;
  color: var(--DARK_BLUE);
  text-align: right;
  font-family: MEDIUM;
}

.percentOff {
  font-size: 14px;
  line-height: 14px;
  color: var(--GREY_DARK);
  text-align: right;
  font-family: MEDIUM;
}
