.container {
  margin-top: 20px;
  flex-direction: row;
  width: 100%;
  position: relative;
}

.input {
  all: unset;
  width: 100%;
  height: 50px;
  border-radius: 16px;
  border: 1px solid var(--GREY_LIGHT);
  background: var(--WHITE);
  text-indent: 28px;
  font-size: 16px;
  line-height: 16px;
  color: var(--DARK_BLUE);
  font-family: REGULAR;
}
input::placeholder {
  color: var(--GREY_DARK);
  font-size: 16px;
  line-height: 16px;
}

@media only screen and (max-width: 780px) {
  .input.input {
    height: 60px;
    font-size: 18px;
    line-height: 18px;
  }
  input::placeholder {
    font-size: 18px;
    line-height: 18px;
  }
}

.inputCompleted.inputCompleted {
  color: var(--GREY_DARK);
}
.inputFocus.inputFocus {
  border: 2px solid var(--VIOLET);
}

.inputError.inputError {
  border: 2px solid var(--RED);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 40px white inset !important;
}

input:focus::placeholder {
  color: transparent;
}

.label {
  display: flex;
  position: absolute;
  margin-top: -5px;
  margin-left: 20px;
  background: var(--WHITE);
  padding: 0px 10px;
  color: var(--VIOLET);
  font-size: 12px;
  line-height: 12px;
  font-family: REGULAR;
}

input:not(:focus):placeholder-shown + label {
  display: none;
}

.checkMark {
  width: 20px;
  height: 20px;
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0%, -50%);
  justify-content: center;
  align-items: center;
}
