.container.container {
  flex: 1;
  border-right: 1px solid var(--GREY_LIGHT);
  transition: 250ms ease-in-out;
}

.mobileTopBar.mobileTopBar {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
  height: 96px;
  border-bottom: 1px solid var(--GREY_LIGHT);
}
.amoBadgeMobile {
  margin-right: 16px;
  width: 82px;
  height: 34px;
}

.menuIcon {
  z-index: 10;
  margin-left: 16px;
  width: 32px;
  height: 32px;
}

.mobileBar.mobileBar {
  position: absolute;
  margin-top: 96px;
  padding-top: 36px;
  width: 250px;
  height: 100%;
}

.hiddenNavBar.hiddenNavBar {
  transform: translateX(-100%);
}

.subContainer.subContainer {
  padding: 0 5%;
  flex: 1;
}

.amoBadge {
  margin: 49px 0 30%;
  width: 96px;
  height: 39px;
}

.line {
  width: 80%;
  border: 1px solid #e4e4e4;
}

.downloadContainer.downloadContainer {
  flex: 1;
  justify-content: flex-end;
  margin-bottom: 30px;
}

@media only screen and (max-width: 780px) {
  .amoBadge {
    display: none;
  }
  .downloadContainer.downloadContainer {
    margin-bottom: 140px;
  }
}
