.updatePaymentDetails.updatePaymentDetails {
  margin-top: 24px;
}

.poweredByStripe.poweredByStripe {
  width: 100px;
  height: 20px;
}

.notice {
  font-family: REGULAR;
  font-size: 16px;
  line-height: 18px;
  color: var(--DARK_BLUE);
}
