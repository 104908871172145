.button {
  height: 50px;
  border-radius: 100px;
  cursor: pointer;
  border: 0;
  min-width: 180px;
}
.buttonText {
  font-size: 16px;
  line-height: 16px;
  padding: 0 20px;
  font-family: SEMI_BOLD;
}
@media only screen and (max-width: 780px) {
  .button.button {
    height: 60px;
  }
  .buttonText.buttonText {
    font-size: 18px;
    line-height: 18px;
  }
}

.darkButton {
  background: var(--DARK_BLUE);
}
.lightButton {
  background: var(--WHITE);
  border: 1px solid var(--GREY_DARK);
}
.darkButtonText {
  color: var(--WHITE);
}
.lightButtonText {
  color: var(--DARK_BLUE);
}

.button:active:not(:disabled) {
  transform: scale(0.9);
  transition: 100ms ease-in-out;
}
