.container.container {
  flex-direction: row;
  align-items: center;
}

.circle.circle {
  justify-content: center;
  align-items: center;
  border: 2px solid var(--DARK_BLUE);
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.disabledCircle.disabledCircle {
  border: 2px solid var(--GREY_DARK);
}

.sectionIndex {
  font-family: SEMI_BOLD;
  font-size: 16px;
  line-height: 16px;
  transform: translateX(-0.5px) translateY(-0.5px);
  color: var(--DARK_BLUE);
}

.disabled.disabled {
  color: var(--GREY_DARK);
}

.title {
  font-family: MEDIUM;
  font-size: 20px;
  line-height: 20px;
  margin-left: 10px;
  font-feature-settings: "liga" off;
  color: var(--DARK_BLUE);
}

@media only screen and (max-width: 780px) {
  .title {
    font-size: 28px;
    line-height: 28px;
  }
}
