.paymentContainer.paymentContainer {
  background: var(--WHITE);
  border: 1px solid var(--GREY_LIGHT);
  border-radius: 24px;
  flex-direction: row;
  height: 163px;
  overflow: hidden;
  margin-top: 24px;
  justify-content: space-between;
  align-items: center;
}

.paymentCardContainer.paymentCardContainer {
  margin-right: 5%;
  align-items: flex-end;
}

.paymentInfoContainer.paymentInfoContainer {
  margin-left: 10%;
}
.paymentInfoTitle {
  font-family: MEDIUM;
  font-size: 24px;
  line-height: 24px;
  color: var(--DARK_BLUE);
}
.paymentInfoSubtitle {
  font-family: REGULAR;
  font-size: 16px;
  line-height: 16px;
  color: var(--DARK_BLUE);
  margin-top: 16px;
}
.update.update {
  margin-top: 16px;
}

@media only screen and (max-width: 780px) {
  .paymentContainer.paymentContainer {
    height: revert;
    padding: 20px 10px 20px 0;
    flex-direction: column;
    justify-content: center;
    align-items: revert;
  }
  .paymentCardContainer.paymentCardContainer {
    margin-top: 20px;
    align-self: flex-end;
  }
}
