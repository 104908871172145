.container.container {
  padding: 53px 0 33px;
  margin-bottom: 30px;
  align-items: center;
  background: var(--VIOLET_LIGHT);
  border-radius: 16px;
  position: relative;
}

.title {
  margin-bottom: 39px;
  white-space: pre-line;
  font-family: REGULAR;
  font-size: 24px;
  line-height: 24px;
  text-align: center;
  color: var(--DARK_BLUE);
}

.googleButton {
  margin-top: 18px;
}

.bottom {
  width: 90%;
  height: 30px;
  background: var(--VIOLET_LIGHT);
  opacity: 0.5;
  position: absolute;
  bottom: -10px;
  border-radius: 16px;
}
