.container.container {
  border-radius: 24px;
  padding: 4% 4% 3% 3%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.titleContainer.titleContainer {
  flex-direction: row;
  margin-bottom: 43px;
}
.buttonContainer.buttonContainer {
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 37px;
}

.buttonSeparator.buttonSeparator {
  margin-left: 25px;
}

.contentContainer.contentContainer {
  flex: 1;
}

.loader.loader {
  align-items: flex-end;
  margin-top: 20px;
  width: revert;
  margin-right: 50px;
}

.title {
  font-family: MEDIUM;
  font-size: 24px;
  line-height: 24px;
  color: var(--DARK_BLUE);
}
.primaryButton.primaryButton {
  width: revert;
  padding: 0 80px;
  min-width: 30%;
}
@media only screen and (max-width: 780px) {
  .container.container {
    border-radius: 24px;
    padding: 8% 8% 6% 6%;
  }
  .buttonContainer.buttonContainer {
    flex-direction: column;
    width: 100%;
  }
  .buttonSeparator.buttonSeparator {
    margin-left: 0px;
    margin-top: 22px;
  }
  .loader.loader {
    align-items: center;
    margin-top: 22px;
    margin-right: 0;
    width: revert;
  }
  .primaryButton.primaryButton {
    padding: revert;
  }
}
