.container.container {
  margin-bottom: 40px;
}

.greetings {
  font-family: MEDIUM;
  font-size: 24px;
  line-height: 24px;
  color: var(--DARK_BLUE);
}

.title {
  font-family: MEDIUM;
  font-size: 48px;
  line-height: 48px;
  letter-spacing: -1px;
  margin-top: 10px;
  color: var(--DARK_BLUE);
}

@media only screen and (max-width: 780px) {
  .greetings {
    font-size: 16px;
    line-height: 16px;
  }
  .title {
    font-size: 32px;
    line-height: 32px;
  }
}
