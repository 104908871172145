.container.container {
  flex-direction: row;
  align-items: flex-end;
}

.priceUnit.priceUnit {
  color: var(--DARK_BLUE);
  font-size: 20px;
  line-height: 20px;
  font-family: MEDIUM;
}

.priceMain.priceMain {
  color: var(--DARK_BLUE);
  font-size: 50px;
  line-height: 50px;
  transform: translateY(5px);
  font-family: MEDIUM;
}

.priceRest.priceRest {
  color: var(--DARK_BLUE);
  font-size: 20px;
  line-height: 20px;
  transform: translateY(-18px);
  font-family: MEDIUM;
}
.priceRestDesktop.priceRestDesktop {
  color: var(--DARK_BLUE);
  font-size: 32px;
  line-height: 32px;
  transform: translateY(-28px);
  font-family: MEDIUM;
}

.duration.duration {
  color: var(--DARK_BLUE);
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 0;
  font-family: REGULAR;
  text-transform: lowercase;
}

.bigFontSizeDesktop.bigFontSizeDesktop {
  font-size: 82px;
  line-height: 82px;
  transform: translateY(8px);
}
.smallFontSizeDesktop.smallFontSizeDesktop {
  font-size: 32px;
  line-height: 32px;
}

.smallTypeText {
  font-family: MEDIUM;
  font-size: 24px;
  line-height: 24px;
  color: var(--DARK_BLUE);
}
.smallTypeDuration {
  margin-top: 8px;
  font-family: REGULAR;
  font-size: 16px;
  line-height: 16px;
  color: var(--DARK_BLUE);
  white-space: pre-line;
}
