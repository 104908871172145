:root {
  --DARK_BLUE: #070c32;
  --VIOLET: #7777ff;
  --VIOLET_LIGHT: #e0e0fc;
  --WHITE: #fff;
  --WHITE_TRANSPARENT: rgba(255,255,255,0.15);
  --GREY_DARK: #999dac;
  --GREY_LIGHT: #e7e7e7;
  --RED: #f00044;
  --GREEN: #04902b;
  --BLACK: #000;
}

body {
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
