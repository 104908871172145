.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 56px;
  height: 56px;
  border-radius: 12px;
  background: var(--VIOLET);
  border: 0px;
  cursor: pointer;
}

.inactiveButton {
  background-color: transparent;
}

.buttonIcon {
  margin: 0 20px;
  filter:  brightness(0) invert(1);
}

.inactiveButtonIcon.inactiveButtonIcon {
  opacity: 0.5;
  filter: invert(74%) sepia(10%) saturate(352%) hue-rotate(190deg) brightness(84%) contrast(85%);
}
.inactiveButtonText.inactiveButtonText {
  color: var(--GREY_DARK);
}
.buttonText {
  color: var(--WHITE);
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  font-family: REGULAR;
}
