.container.container {
  background: var(--VIOLET_LIGHT);
  border-radius: 24px;
  flex-direction: row;
  height: 262px;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.leftContainer.leftContainer {
  padding-left: 10%;
  height: 100%;
  justify-content: flex-end;
}

.priceDurationWrapper.priceDurationWrapper {
  margin-top: 53px;
  margin-bottom: 39px;
}
.rightContainer.rightContainer {
  height: 100%;
  align-items: flex-end;
  justify-content: flex-end;
}

.button.button {
  margin: 0 39px 39px 0;
}
.discount.discount {
  margin-bottom: 73px;
}

@media only screen and (max-width: 780px) {
  .container.container {
    flex-direction: column;
    background: var(--VIOLET_LIGHT);
    border-radius: 24px;
    align-items: revert;
    height: revert;
    justify-content: revert;
    overflow: hidden;
  }
  .mobileContainer.mobileContainer {
    width: 85%;
    align-self: center;
  }
  .divider {
    margin-top: 40px;
  }
  .discount.discount {
    align-self: flex-end;
    margin-top: 29px;
    padding: 5px 0;
    margin-bottom: 32px;
  }
  .button {
    width: 100%;
    margin: 0;
  }
}
