.container.container {
  justify-content: center;
  align-items: center;
}
.buttonWrapper.buttonWrapper {
  pointer-events: none;
  z-index: 9999;
  width: 100%;
}
.paypalButtonWrapper.paypalButtonWrapper {
  position: absolute;
  z-index: 0;
  opacity: 0.001;
  width: 90%;
  padding-left: 20px;
}

@media only screen and (max-width: 780px) {
  .paypalButtonWrapper.paypalButtonWrapper {
    width: 100%;
    padding-left: 0;
  }
}
