@media only screen and (min-width: 781px) {
    .cardForm.cardForm {
        width: 100%;
        height: 100px;
    }
}
@media only screen and (max-width: 780px) {
    .cardForm.cardForm {
        width: 100%;
        height: 120px;
    }
}

.cardNumberField.cardNumberField {
    flex-direction: column;
    height: 50%;
    justify-content: center;
    padding-left: 24px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    justify-content: center;
}

.cardFormInline.cardFormInline {
    flex-direction: row;
    height: 50%;
}

.cardExpirationField.cardExpirationField {
    width: 50%;
    justify-content: center;
    padding-left: 24px;
    border-bottom-left-radius: 20px;
    position: relative;
}
.cardCVCForm.cardCVCForm {
    width: 50%;
    justify-content: center;
    padding-left: 24px;
    border-bottom-right-radius: 20px;
    position: relative;
}

.cardValidationError.cardValidationError {
    font-size: 13px;
    line-height: 13px;
    font-family: REGULAR;
    color: RED;
    margin-top: 8px;
}

.fieldNotValidCircle.fieldNotValidCircle {
    width: 16px;
    height: 16px;
    border: 1px solid var(--RED);
    border-radius: 8px;
    position: absolute;
    justify-content: center;
    align-items: center;
    right: 5%;
}

.exclamationMark {
    width: 2px;
    height: 8px;
}
