.button {
  width: 100%;
  height: 50px;
  border-radius: 100px;
  cursor: pointer;
  border: 0;
  background: var(--VIOLET);
  box-shadow: 0px 16px 24px rgba(119, 119, 255, 0.32);
  position: relative;
}
.buttonText {
  color: var(--WHITE);
  font-size: 16px;
  line-height: 16px;
  font-family: SEMI_BOLD;
}
.buttonIcon {
  height: 25px;
}

.circleIcon.circleIcon {
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: var(--WHITE_TRANSPARENT);
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translate(0%, -50%);
  justify-content: center;
  align-items: center;
}

.chevron{
  height: 10px;
  width: 6px;
}

@media only screen and (max-width: 780px) {
  .button {
    height: 60px;
  }
  .buttonText {
    font-size: 18px;
    line-height: 18px;
  }
  .buttonIcon {
    height: 30px;
  }
}

.button.button:disabled {
  background-color: var(--GREY_LIGHT);
  box-shadow: revert;
}

.button:hover:not(:disabled) {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #7777ff;
}
.button:active:not(:disabled) {
  transform: scale(0.9);
  transition: 100ms ease-in-out;
}
