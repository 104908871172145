.container.container {
    flex-direction: row;
    align-items: flex-end;
}

.daysMain.daysMain {
    color: var(--DARK_BLUE);
    font-size: 82px;
    line-height: 82px;
    margin-bottom: 0;
    font-family: MEDIUM;
    transform: translateY(5px);
}

.text.text {
    color: var(--DARK_BLUE);
    font-size: 32px;
    line-height: 32px;
    margin-bottom: 0;
    font-family: SEMI_BOLD;
}

.bigFontSizeDesktop.bigFontSizeDesktop {
    font-size: 82px;
    line-height: 82px;
}

.shortDaysMain {
    color: var(--DARK_BLUE);
    margin-right: 8px;
    margin-top: 0;
    font-size: 40px;
    font-family: MEDIUM;
    transform: translateY(5px);
}

.shortText {
    color: var(--DARK_BLUE);
    font-size: 24px;
    font-family: SEMI_BOLD;
}

@media only screen and (min-width: 781px) {
    .shortDaysMain {
        margin-top: 18px;
        font-size:82px;
        line-height: 82px;
        transform: translate(5px);
    }
    .shortText {
        font-size: 32px
    }
}