@media only screen and (min-width: 781px) {
  .container.container {
    height: 100vh;
    width: 100vw;
    flex-direction: row;
    overflow: hidden;
  }
  .leftContainer.leftContainer {
    background: rgb(168, 173, 253);
    background: linear-gradient(
      323deg,
      rgba(168, 173, 253, 0.3) 0%,
      rgba(255, 255, 255, 1) 51%,
      rgba(168, 173, 253, 0.3) 100%
    );
    width: 56vw;
    height: 100vh;
  }
  .rightContainer.rightContainer {
    width: 44vw;
    overflow-y: auto;
  }
}

@media only screen and (max-width: 780px) {
  .container.container {
    flex-direction: column;
    height: 100vh;
  }
  .leftContainer.leftContainer {
    flex-direction: column;
    background: rgb(168, 173, 253);
    background: linear-gradient(
      323deg,
      rgba(168, 173, 253, 0.3) 0%,
      rgba(255, 255, 255, 1) 51%,
      rgba(168, 173, 253, 0.3) 100%
    );
  }
  .rightContainer.rightContainer {
    flex: 1;
    background: linear-gradient(42deg, rgba(249, 205, 230, 1) 0%, rgba(184, 184, 253, 0) 20%);
    background-attachment: fixed;
  }
}
