.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gradientAvatarContainer {
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.gradientLoader {
  position: absolute;
  z-index: 0;
  width: 150px;
  height: 150px;
}

.avatarContainer.avatarContainer {
  z-index: 1;
  border: 1px solid white;
  width: 72px;
  height: 72px;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  position: relative;
}

.avatarBackground.avatarBackground {
  background-color: var(--GREY_LIGHT);
  position: absolute;
  width: 58px;
  height: 58px;
  border-radius: 20px;
  z-index: 0;
}

.trainerAvatar {
  width: 56px;
  height: 56px;
  z-index: 1;
  border-radius: 22px;
}

.titleContainer {
  max-width: 327px;
  display: flex;
  justify-content: center;
}

.title {
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "liga" off;
  margin-top: 30px;
  margin-bottom: 0;
  font-family: REGULAR;
  font-weight: 400;
  color: var(--DARK_BLUE);
  white-space: pre-line;
}

.titleBold {
  font-size: 28px;
  line-height: 28px;
  margin-top: 0;
  font-weight: 800;
  text-align: center;
  font-feature-settings: "liga" off;
  font-family: REGULAR;
  color: var(--DARK_BLUE);
  white-space: pre-line;
}

.subtitle {
  font-family: MEDIUM;
  font-size: 16px;
  line-height: 20px;
  font-weight: 300;
  color: var(--DARK_BLUE);
  text-align: center;
}

.planContainer {
  justify-content: center;
  align-items: center;
  padding-bottom: 40px;
  width: 327px;
}
