.avatarPlanContainer.avatarPlanContainer {
  flex-direction: row;
  background: rgb(168, 173, 253);
  background: linear-gradient(
    323deg,
    rgba(168, 173, 253, 0.3) 0%,
    rgba(255, 255, 255, 1) 51%,
    rgba(168, 173, 253, 0.3) 100%
  );
}
.avatarPlanContainerDesktop.avatarPlanContainerDesktop {
  background: rgb(168, 173, 253);
  height: 100%;
  background: linear-gradient(
    323deg,
    rgba(168, 173, 253, 0.3) 0%,
    rgba(255, 255, 255, 1) 51%,
    rgba(168, 173, 253, 0.3) 100%
  );
}

.planContainer.planContainer {
  justify-content: space-between;
  padding: 20px 20px;
  width: 60vw;
}
.planContainerDesktop.planContainerDesktop {
  padding: 20px 40px;
}

.planTextWrapper.planTextWrapper {
  margin-top: 56px;
  margin-left: 20px;
}

.thenPriceByUnit {
  font-family: REGULAR;
  color: var(--DARK_BLUE);
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1px;
  margin-top: 18px;
}

.yearlyPlan {
  color: var(--VIOLET);
  font-family: MEDIUM;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
}

.plansBy {
  color: var(--VIOLET);
  font-family: MEDIUM;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 15px;
}

.amoLogo {
  width: 96px;
  height: 38px;
}

@media only screen and (max-width: 780px) {
  .amoLogo {
    width: 57px;
    height: 23px;
  }
  .yearlyPlan.yearlyPlan {
    font-size: 13px;
    line-height: 15px;
  }
  .thenPriceByUnit {
    margin-top: 14px;
  }
}

.coachContainer.coachContainer {
  align-items: flex-end;
  justify-content: flex-end;
  height: 264px;
  padding-right: 20px;
  width: 40vw;
  overflow: hidden;
}
.coachContainerDesktop.coachContainerDesktop {
  flex: 1;
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 5%;
  overflow: hidden;
}
