.container.container {
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  min-height: 130vh;
}

.mobileBarVisible {
  transform: translateX(270px);
}

.sidebarContainer.sidebarContainer {
  flex: 0.18;
  min-width: 226px;
}
.contentContainer.contentContainer {
  transition: 250ms ease-in-out;
  flex: 0.82;
  flex-direction: column;
  padding: 2% 5%;
  background: var(--WHITE);
}

@media only screen and (max-width: 780px) {
  .container.container {
    flex-direction: column;
    flex: 1;
    overflow: hidden;
  }
  .contentContainer.contentContainer {
    flex: 1;
    width: 90%;
  }
}
