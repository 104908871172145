.parallelogram.parallelogram {
  background: var(--VIOLET);
  width: 110px;
  height: 29px;
  letter-spacing: 1px;
  transform: skew(-25deg) translateX(38px);
  justify-content: center;
}

.discount {
  margin-left: 10px;
  color: var(--WHITE);
  text-align: left;
  font-family: BOLD_ITALIC;
  font-size: 22px;
  line-height: 22px;
  transform: skew(25deg);
  font-weight: bold;
}
