.uploadFile {
  display: none;
}

.avatarWrapper {
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
}

.changeAvatar {
  margin-left: 11px;
}

.updatePassword {
  font-family: MEDIUM;
  font-size: 18px;
  line-height: 18px;
  color: var(--DARK_BLUE);
  margin-top: 51px;
}

.newPasswordWrapper.newPasswordWrapper {
  flex-direction: row;
  width: 100%;
}

.confirmPasswordSeparator {
  margin: 0 11px;
}
