.cardInfo.cardInfo {
  flex-direction: row;
  align-items: center;
}
.cardIcon.cardIcon {
  width: 44px;
  height: 29px;
  margin-left: 10px;
}
.iconWithoutText.iconWithoutText {
  width: 80px;
  height: 21px;
}
.card.card {
  font-family: REGULAR;
  font-size: 14px;
  line-height: 14px;
  color: var(--DARK_BLUE);
}
