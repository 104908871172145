.container {
  flex: 1;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.icon {
  width: 88px;
  height: 88px;
}

.textButtonContainer {
  align-items: center;
  max-width: 385px;
}

.line {
  flex: 1;
  width: 254px;
  margin-top: 27px;
  border-top: 1px solid var(--GREY_LIGHT);
}

.title {
  font-family: MEDIUM;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  font-feature-settings: "liga" off;
  color: var(--DARK_BLUE);
  margin-top: 34px;
}

.titleFailed {
  width: 300px;
}

.goBack {
  margin-top: 29px;
}

.downloadApp {
  margin-top: 19px;
  font-family: REGULAR;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.1px;
  color: var(--DARK_BLUE);
  text-align: center;
}

.badgeContainer.badgeContainer {
  flex-direction: row;
  margin-top: 26px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.downArrow {
  margin-top: 26px;
}

.separator {
  margin: 0 20px;
}

.afterTrialMessage {
  font-family: REGULAR;
  font-size: 13px;
  color: var(--GREY_DARK);
  text-align: center;
  margin-top: 19px;
}

@media only screen and (max-height: 750px) {
  .iconContainer {
    padding-top: 36px;
  } 
}

@media only screen and (max-width: 780px) {
  .container {
    justify-content: start;
    padding-top: 145px;
  }
  .textButtonContainer {
    margin: 0 40px;
  }

  .separator {
    margin-top: 10px;
  }

  .downloadButton {
    width: 100%;
  }

  .badgeContainer.badgeContainer {
    flex-direction: column;
    margin-top: 26px;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 780px) and (max-width: 1050px) {
  .badgeContainer.badgeContainer {
    flex-direction: column;
    margin-top: 26px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
}