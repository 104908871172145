@font-face {
  font-family: "MEDIUM";
  src: local("MEDIUM"), url(assets/fonts/Medium.ttf) format("truetype");
}

@font-face {
  font-family: "SEMI_BOLD";
  src: local("SEMI_BOLD"), url(assets/fonts/SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "REGULAR";
  src: local("REGULAR"), url(assets/fonts/Regular.ttf) format("truetype");
}

@font-face {
  font-family: "BOLD_ITALIC";
  src: local("BOLD_ITALIC"), url(assets/fonts/BoldItalic.ttf) format("truetype");
}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 17, 2021 */

@font-face {
  font-family: "BOLD_ITALIC";
  src: url("assets/fonts/bolditalic-webfont.woff2") format("woff2"),
    url("assets/fonts/bolditalic-webfont.woff") format("woff");
}
@font-face {
  font-family: "MEDIUM";
  src: url("assets/fonts/medium-webfont.woff2") format("woff2"), url("assets/fonts/medium-webfont.woff") format("woff");
}
@font-face {
  font-family: "REGULAR";
  src: url("assets/fonts/regular-webfont.woff2") format("woff2"),
    url("assets/fonts/regular-webfont.woff") format("woff");
}
@font-face {
  font-family: "SEMI_BOLD";
  src: url("assets/fonts/semibold-webfont.woff2") format("woff2"),
    url("assets/fonts/semibold-webfont.woff") format("woff");
}
