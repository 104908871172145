.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 15px;
    font-family: REGULAR;
    font-size: 18px;
}


.boldText {
    font-family: SEMI_BOLD;
}
