.container.container {
  cursor: pointer;
  border: none;
  width: 100%;
  max-width: 327px;
  border-radius: 24px;
  height: 150px;
  margin-top: 20px;
  overflow: hidden;
  justify-content: center;
  background: linear-gradient(
    42deg,
    rgba(184, 184, 253, 0.5) 0%,
    rgba(184, 184, 253, 0.1) 50%,
    rgba(184, 184, 253, 0.5) 100%
  );
}
@media only screen and (max-width: 780px) {
  .container.container {
    height: 200px;
  }
}

.container:hover {
  transform: scale(1.1);
  transition: 250ms ease-in-out;
}
.container:active {
  transform: scale(0.95);
  transition: 250ms ease-in-out;
}

.innerContainer.innerContainer {
  margin-left: 22px;
}
.container:hover > .innerContainer {
  transform: scale(0.9);
  transition: 250ms ease-in-out;
}
.container:active > .innerContainer {
  transform: scale(1);
  transition: 250ms ease-in-out;
}

.titleContainer.titleContainer {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.title {
  text-align: left;
  color: var(--DARK_BLUE);
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.dayFreeTrial {
  text-align: left;
  font-size: 16px;
  line-height: 16px;
  margin-top: 14px;
  font-feature-settings: "liga" off;
  font-family: MEDIUM;
  color: var(--DARK_BLUE);
  white-space: pre-line;
}

.getStartedButton {
  margin: 16px 22px 0 22px;
  height: 50px;
  border: 1px solid var(--DARK_BLUE);
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.getStartedText {
  color: var(--DARK_BLUE);
  text-align: center;
  justify-self: center;
  font-size: 16px;
  font-family: REGULAR;
}