.cardForm.cardForm {
  width: 50%;
}
.titleContainer.titleContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}

.securePaymentLockWrapper.securePaymentLockWrapper {
  flex-direction: row;
  padding-left: 20px;
  align-items: center;
}

.securePayment {
  padding-left: 7px;
  font-family: REGULAR;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  letter-spacing: -0.165px;
  color: var(--GREEN);
}
