.footerText {
    font-size: 15px;
    line-height: 15px;
    color: var(--GREY_DARK);
    text-align: center;
    margin-bottom: 20px;
    margin-top: 66px;
    font-family: MEDIUM;
}

.link {
    color: var(--GREY_DARK);
    padding: 0 2px;
    text-decoration: none;
}

.privacyAndTerms {
    font-size: 18px;
    line-height: 18px;
    font-family: REGULAR;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 0 5px;
}
