.container.container {
  background: rgb(168, 173, 253);
  background: linear-gradient(
    323deg,
    rgba(168, 173, 253, 0.3) 0%,
    rgba(255, 255, 255, 1) 51%,
    rgba(168, 173, 253, 0.3) 100%
  );
  position: relative;
  min-height: 100vh;
}

.amo {
  margin: 30px 0 0 10%;
  width: 96px;
  height: 39px;
}

.accountFormContainer.accountFormContainer {
  width: 40%;
  height: 50%;
  margin-top: 80px;
  align-items: center;
  background: var(--WHITE);
  justify-content: center;
  align-self: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 24px;
  min-width: 400px;
}

.accountForm.accountForm {
  width: 80%;
}

.button.button {
  margin-top: 20px;
}

.buttonText.buttonText {
  font-family: MEDIUM;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: var(--WHITE);
}

@media only screen and (max-width: 780px) {
  .accountFormContainer.accountFormContainer {
    width: 90%;
    min-width: revert;
    justify-content: center;
    margin-top: 20%;
  }
  .amo.amo {
    margin:30px 0 0 0;
    align-self: center;
  }
}
