.container {
  width: 100%;
  border: 1px solid var(--GREY_LIGHT);
  border-radius: 16px;
  margin-bottom: 26px;
  padding: 30px 0px;
}

.inlineContainer {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.containerActive {
  border: 1px solid var(--VIOLET);
}

.inline.inline {
  flex-direction: row;
  align-items: center;
}

.radioButton.radioButton {
  margin: 0 28px;
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  border: 2px solid var(--GREY_DARK);
}
.selectedRadioButton.selectedRadioButton {
  width: 14px;
  height: 14px;
  border: 6px solid var(--VIOLET);
}

.title {
  font-family: REGULAR;
  font-size: 24px;
  line-height: 24px;
  transform: translateY(2px);
  color: var(--DARK_BLUE);
}

.cardContainer {
  margin-right: 52px;
}

.icon {
  margin-top: 10px;
}
.childContainer {
  width: 50%;
  max-width: 600px;
  margin: 30px 0 20px 80px;
}
@media only screen and (max-width: 780px) {
  .inline.inline {
    align-items: revert;
  }
}
