.cardFormContainer.cardFormContainer {
  margin-top: 34px;
}

.payWithCardContainer.payWithCardContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 34px 0;
}

.line {
  flex: 1;
  border-top: 1px solid var(--GREY_DARK);
}

.payWithCard {
  padding: 0 20px;
  color: var(--GREY_DARK);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: MEDIUM;
}

.paypalButton.paypalButton {
  background: var(--WHITE);
  border: 1px solid var(--DARK_BLUE);
  box-shadow: revert;
}
.paypalButton.paypalButton:hover:not(:disabled) {
  background: var(--WHITE);
}

.paymentButton.paymentButton {
  background: BLACK;
  box-shadow: revert;
  margin-bottom: 14px;
}

.paymentButton.paymentButton:hover:not(:disabled) {
  background: rgba(0, 0, 0, 0.8);
}
