.container.container {
  padding: 0 5% 40px 5%;
}

.poweredByStripe {
  width: 109px;
  height: 15px;
  align-self: center;
  margin-top: 24px;
}

.trialText {
  font-size: 15px;
  line-height: 15px;
  color: var(--GREY_DARK);
  text-align: center;
  margin-top: 16px;
  font-family: MEDIUM;
}
