.container.container {
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin: 39px 0 34px;
}

.line {
  flex: 1;
  border-top: 1px solid var(--GREY_DARK);
}

.payWithCard {
  padding: 0 20px;
  color: var(--GREY_DARK);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: MEDIUM;
}
