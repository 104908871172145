.planTitle {
  font-family: SEMI_BOLD;
  font-size: 40px;
  line-height: 35px;
  letter-spacing: -1px;
  color: var(--DARK_BLUE);
}

.planSubtitle {
  font-family: REGULAR;
  font-size: 14px;
  line-height: 14px;
  color: var(--DARK_BLUE);
  margin-top: 10px;
}

@media only screen and (max-width: 780px) {
  .planTitle {
    font-size: 24px;
    line-height: 24px;
  }
}
