.container {
  flex: 1;
}

.avatar {
  margin-bottom: 49px;
}

.inlineContainer {
  flex-direction: row;
  border-bottom: 1px solid var(--GREY_LIGHT);
}

.emailContainer {
  margin-top: 26px;
}

.entryText {
  display: flex;
  width: 35%;
  font-family: REGULAR;
  font-size: 14px;
  line-height: 14px;
  color: var(--GREY_DARK);
  padding-bottom: 20px;
}

.entryValue {
  text-align: left;
  font-family: REGULAR;
  font-size: 14px;
  line-height: 14px;
  color: var(--DARK_BLUE);
}
