.container {
    flex-direction: row;
    background-color: var(--GREY_LIGHT);
    justify-content: space-between;
    padding: 0 11px 0 9px;
    align-items: center;
    border-radius: 10px;
    height: 40px;
    width: 170px;
}

.tagIcon {
    width: 18px;
}

.text {
    font-size: 18px;
    font-family: REGULAR;
    color: var(--DARK_BLUE);
}

.closeIcon {
    width: 12px;
}
